import React, { useState } from 'react';
import { Button, DialogContainer, SelectField } from 'react-md';
import { OrganizationOutput } from '@mmc-csm/shared';

interface OrgFeatureProps {
  addOnServices : OrganizationOutput['addOnServices'],
  features : OrganizationOutput['metaData']['features']
  onChangeOrg : (features : OrganizationOutput['metaData']['features'], updatedAddOnServices: OrganizationOutput['addOnServices']) => void,
}

export enum FeatureToggle {
  ON = 'Enabled',
  OFF = 'Disabled'
}

const OrgFeatures = ({ addOnServices, features, onChangeOrg }: OrgFeatureProps) => {
  const [visible, setVisible] = useState(false);
  const leadItems = ['Lead Finder', 'Lead Finder Basic', 'None'];
  const translatedLeadItems = ['pro', 'standard', 'no-plan'];
  const translatedBooleanItems = [true, false];
  const toggleOptions = [FeatureToggle.ON, FeatureToggle.OFF];
  const [selectedLead, setSelectedLead] = useState(leadItems[translatedLeadItems.indexOf(addOnServices.leadGen || 'standard')]);
  const [selectedDataMerge, setSelectedDataMerge] = useState(addOnServices.dupeCheck ? FeatureToggle.ON : FeatureToggle.OFF);
  const [selectedTerritories, setSelectedTerritories] = useState(addOnServices.territories ? FeatureToggle.ON : FeatureToggle.OFF);
  const [phi, setPhi] = useState(addOnServices.hipaaEnabled ? FeatureToggle.ON : FeatureToggle.OFF);
  const [demographicData, setDemographicData] = useState(addOnServices.demographicData ? FeatureToggle.ON : FeatureToggle.OFF);
  const [coreMaps, setCoreMaps] = useState(features && features.map && features.map.enabled ? FeatureToggle.ON : FeatureToggle.OFF);
  const [pinLegend, setPinLegend] = useState(features && features.disallowColor && features.disallowColor.enabled ? FeatureToggle.OFF : FeatureToggle.ON);
  const [emailService, setEmailService] = useState(addOnServices.emailService ? FeatureToggle.ON : FeatureToggle.OFF);
  const [mileageTracking, setMileageTracking] = useState(addOnServices.mileageTracking ? FeatureToggle.ON : FeatureToggle.OFF);

  const saveInput = () => {
    const leadInput = selectedLead;
    const dataMergeInput = selectedDataMerge;
    const territoriesInput = selectedTerritories;
    const phiInput = phi;
    const demographicDataInput = demographicData;
    addOnServices.leadGen = leadInput;
    addOnServices.dupeCheck = !!dataMergeInput;
    addOnServices.emailService = !!emailService;
    const metaData = {
      map: { enabled: coreMaps === FeatureToggle.ON },
      disallowColor: { enabled: pinLegend === FeatureToggle.OFF },
    };

    onChangeOrg(
      metaData,
      {
        demographicData: translatedBooleanItems[toggleOptions.indexOf(demographicDataInput)],
        dupeCheck: translatedBooleanItems[toggleOptions.indexOf(dataMergeInput)],
        emailService: translatedBooleanItems[toggleOptions.indexOf(emailService)],
        hipaaEnabled: translatedBooleanItems[toggleOptions.indexOf(phiInput)],
        leadGen: translatedLeadItems[leadItems.indexOf(leadInput)],
        mileageTracking: translatedBooleanItems[toggleOptions.indexOf(mileageTracking)],
        territories: translatedBooleanItems[toggleOptions.indexOf(territoriesInput)],
      },
    );
    setVisible(!visible);
  };

  const actions = [<Button flat onClick={() => setVisible(!visible)}>Cancel</Button>,
    <Button flat primary onClick={saveInput}>Save</Button>];

  const handleChangeTerritories = (value: any) => {
    if (value === FeatureToggle.ON) {
      setDemographicData(value);
    }
    setSelectedTerritories(value);
  };

  const FeatureToggler = () => (
    <div>
      <DialogContainer
        visible={visible}
        actions={actions}
        width={350}
        id="manageFeatures"
      >
        <h2>Manage Features</h2>

        <SelectField
          id="coreMaps"
          style={{ width: 300, paddingBottom: 20 }}
          label="Core Maps"
          defaultValue={coreMaps}
          onChange={(value: any) => setCoreMaps(value)}
          menuItems={toggleOptions}
        />
        <SelectField
          id="dataMerging"
          style={{ width: 300, paddingBottom: 20 }}
          label="Data Merging"
          defaultValue={selectedDataMerge}
          onChange={(value: any) => setSelectedDataMerge(value)}
          menuItems={toggleOptions}
        />
        <SelectField
          id="emailService"
          style={{ width: 300, paddingBottom: 20 }}
          label="Email Service"
          defaultValue={emailService}
          onChange={(value: any) => setEmailService(value)}
          menuItems={toggleOptions}
        />
        <SelectField
          id="demographicData"
          style={{ width: 300, paddingBottom: 10 }}
          label="Demographic Data"
          defaultValue={demographicData}
          onChange={(value: any) => setDemographicData(value)}
          menuItems={toggleOptions}
          disabled={selectedTerritories === FeatureToggle.ON}
          helpText={selectedTerritories === FeatureToggle.ON ? 'Demographic data is always enabled for organizations that have Territories' : undefined}
        />
        <SelectField
          id="leadFinder"
          style={{ width: 300, paddingBottom: 20 }}
          label="Lead Finder"
          defaultValue={selectedLead}
          onChange={(value: any) => setSelectedLead(value)}
          menuItems={leadItems}
        />
        <SelectField
          id="phi"
          style={{ width: 300, paddingBottom: 10 }}
          label="PHI"
          defaultValue={phi}
          onChange={(value: any) => setPhi(value)}
          menuItems={toggleOptions}
        />
        <SelectField
          id="pinLegend"
          style={{ width: 300, paddingBottom: 20 }}
          label="Legacy Record Colors"
          defaultValue={pinLegend}
          onChange={(value: any) => setPinLegend(value)}
          menuItems={toggleOptions}
        />
        <SelectField
          id="mileageTracking"
          style={{ width: 300, paddingBottom: 20 }}
          label="Mileage Tracking"
          defaultValue={mileageTracking}
          onChange={(value: any) => setMileageTracking(value)}
          menuItems={toggleOptions}
        />
        <SelectField
          id="territories"
          style={{ width: 300, paddingBottom: 10 }}
          label="Territories"
          defaultValue={selectedTerritories}
          onChange={handleChangeTerritories}
          menuItems={toggleOptions}
        />
      </DialogContainer>

    </div>
  );


  return (
    <div>
      <Button primary flat onClick={() => setVisible(!visible)}>MANAGE FEATURES</Button>
      <FeatureToggler />
    </div>
  );
};

export default OrgFeatures;
